import gql from "graphql-tag";

// IMPORTANT: keep this query in its own file for optimal code chunking.
export const queryCartProjection = gql`
  query CartProjection($userId: String!, $queryTimeStamp: String) {
    cartProjection(userId: $userId, queryTimeStamp: $queryTimeStamp) {
      id
      cartType
      totalLines
      totalItemQuantity
    }
  }
`;
